<template>

  <!--
    @NOTE: these are in order of priority (via v-else-if directives), since we
    only want to show one banner at a time
  -->

  <template v-if="!isUserDataLoading">

    <!-- action limit error -->
    <div class="banner error" v-if="shouldShowActionLimitErrors">
      <div class="container alert error">
        <div class="columned">
          <div>
            <strong class="title">Your Patches are paused until {{ usageCycleEndsAt }}</strong>
            <p>
              You’ve used 100% of your monthly Actions. You may be missing important alerts about your contracts and wallets.
            </p>
            <p v-if="userPlan.slug === 'free'">
              Upgrade to resume service now.
              <router-link to="/account/plans#compare">View plans</router-link>
            </p>
            <p v-if="userPlan.slug === 'starter'">
              Upgrade your plan, or activate Pay per Action to prevent service interruptions.
              <router-link to="/account/plans#compare">View options</router-link>
            </p>
            <p v-if="userPlan.slug === 'pro'">
              Activate Pay per Action to resume service now.
              <router-link to="/account/plans#pay-per-action">Learn more</router-link>
            </p>
          </div>
          <div class="flex-grow" />
          <div class="action-button" v-if="userPlan.slug === 'starter'">
            <router-link class="button small tertiary" to="/account/plans#compare">Upgrade</router-link>
          </div>
          <div class="action-button" v-if="userPlan.slug === 'pro'">
            <router-link class="button small tertiary" to="/account/plans#pay-per-action">Explore Pay per Action</router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- failed payment error (plan canceled) -->
    <div class="banner error" v-else-if="shouldShowFailedPaymentErrors && !userFlags.hasDismissedFailedPaymentBanner">
      <div class="alert error" :class="{ 'container': willPPADisable }">
        <div class="columned">
          <div>
            <strong class="title" v-if="willPPADisable">{{ userPlan.name }} plan canceled, Pay per Action deactivated</strong>
            <p>
              Your Dispatch {{ userPlan.canceledStripeProduct.name }} plan has been canceled
              <template v-if="willPPADisable">, and Pay per Action deactivated</template>
              due to payment issues. Upgrade to regain access to premium benefits.
              <router-link to="/account/plans#compare">View plans</router-link>
            </p>
          </div>
          <div class="flex-grow" />
          <div>
            <button class="close-button link" @click="dismiss('hasDismissedFailedPaymentBanner')"><CloseIcon /></button>
          </div>
        </div>
      </div>
    </div>

    <!-- failed payment warning (could not renew plan) -->
    <div class="banner error" v-else-if="shouldShowFailedPaymentWarnings">
      <div class="alert error" :class="{ 'container': willPPADisable }">
        <div class="columned">
          <template v-if="willPPADisable">
            <div>
              <strong class="title">Please update your payment info</strong>
              <p>
                There’s a billing issue. Please update your payment information by
                {{ failedPaymentGracePeriodEndsAt }} to avoid losing access to Dispatch {{ userPlan.name }}
                and Pay per Action. If your {{ userPlan.name }} plan is canceled,
                you’ll also lose Pay per Action overage protection.
              </p>
            </div>
            <div class="flex-grow" />
            <div class="action-button">
              <button
                class="small tertiary"
                :disabled="isLoadingStripeBillingPortal"
                @click="openStripeBillingPortal('payment-methods')"
              >
                Update payment info
            </button>
            </div>
          </template>
          <template v-else>
            <div>
              <p>
                There’s a billing issue. Please update your payment information by
                {{ failedPaymentGracePeriodEndsAt }} to avoid losing access to Dispatch {{ userPlan.name }}.
                <button
                  class="link"
                  :disabled="isLoadingStripeBillingPortal"
                  @click="openStripeBillingPortal('payment-methods')"
                >
                  Update payment info
                </button>
              </p>
            </div>
          </template>
        </div>
      </div>
    </div>

    <!-- action limit warning -->
    <div class="banner warning" v-else-if="shouldShowActionLimitWarnings && !userFlags.hasDismissedActionLimitWarningBanner">
      <div class="alert warning" :class="{ 'container': userPlan.slug !== 'free' }">
        <div class="columned">
          <div>
            <strong class="title" v-if="userPlan.slug !== 'free'">
              Heads up!
            </strong>
            <p>
              You’ve used {{ actionsUsedPercentage }}% of your monthly Actions. Your Patches will pause if you use 100% before {{ usageCycleEndsAt }}.
              <router-link to="/account/plans#compare" v-if="userPlan.slug === 'free'">Upgrade plan</router-link>
            </p>
            <p v-if="userPlan.slug === 'starter'">
              Upgrade your plan, or activate Pay per Action to prevent service interruptions.
              <router-link to="/account/plans#compare">View options</router-link>
            </p>
            <p v-if="userPlan.slug === 'pro'">
              Pay per Action can help prevent service interruptions.
              <router-link to="/account/plans#pay-per-action">Learn more</router-link>
            </p>
          </div>
          <div class="flex-grow" />
          <div class="action-button" v-if="userPlan.slug === 'starter'">
            <router-link class="button small tertiary" to="/account/plans#compare">Upgrade</router-link>
          </div>
          <div class="action-button" v-if="userPlan.slug === 'pro'">
            <router-link class="button small tertiary" to="/account/plans#pay-per-action">Explore Pay per Action</router-link>
          </div>
          <div>
            <button class="close-button link" @click="dismiss('hasDismissedActionLimitWarningBanner')"><CloseIcon /></button>
          </div>
        </div>
      </div>
    </div>

    <!-- pending downgrade -->
    <div class="banner info" v-else-if="willSubscriptionDowngrade && !userFlags.hasDismissedPendingDowngradeBanner">
      <div class="alert info" :class="{ 'container': willPPADisable }">
        <div class="columned">
          <template v-if="willPPADisable">
            <div>
              <strong class="title">
                Your {{ userPlan.name }} plan and Pay per Action end {{ billingPeriodEndsAt }}
              </strong>
              <p>
                You've chosen to downgrade. Your plan will automatically switch to {{ upcomingSubscriptionStripeProduct.name }},
                and Pay per Action will be deactivated at the end of your current
                billing cycle. You can upgrade at any time.
                <router-link to="/account/plans#compare">Manage plan</router-link>
              </p>
            </div>
          </template>
          <template v-else>
            <div>
              <p>
                You've downgraded your plan and will switch to Dispatch {{ upcomingSubscriptionStripeProduct.name }} on {{ billingPeriodEndsAt }}.
                <router-link to="/account/plans#compare">Manage plan</router-link>
              </p>
            </div>
          </template>
          <div class="flex-grow" />
          <div>
            <button class="close-button link" @click="dismiss('hasDismissedPendingDowngradeBanner')"><CloseIcon /></button>
          </div>
        </div>
      </div>
    </div>

  </template>

</template>

<script>

  import { mapState, mapGetters } from 'vuex'

  import CloseIcon from '@/assets/icons/close.svg'

  import useStripeBillingPortal from '@/composables/useStripeBillingPortal'

  export default {
    components: {
      CloseIcon,
    },
    computed: {
      ...mapState('user', [
        'userPlan',
        'userFlags',
        'isUserDataLoading',
      ]),
      ...mapGetters('user', [
        'willPPADisable',
        'usageCycleEndsAt',
        'billingPeriodEndsAt',
        'actionsUsedPercentage',
        'willSubscriptionDowngrade',
        'shouldShowActionLimitErrors',
        'shouldShowFailedPaymentErrors',
        'shouldShowActionLimitWarnings',
        'failedPaymentGracePeriodEndsAt',
        'shouldShowFailedPaymentWarnings',
        'upcomingSubscriptionStripeProduct',
      ]),
    },
    setup() {
      const { isLoadingStripeBillingPortal, openStripeBillingPortal } = useStripeBillingPortal()
      return { isLoadingStripeBillingPortal, openStripeBillingPortal }
    },
    created() {
      if (!this.userFlags) return
      this.flags.hasDismissedFailedPaymentBanner = !!this.userFlags.hasDismissedFailedPaymentBanner
      this.flags.hasDismissedPendingDowngradeBanner = !!this.userFlags.hasDismissedPendingDowngradeBanner
      this.flags.hasDismissedActionLimitWarningBanner = !!this.userFlags.hasDismissedActionLimitWarningBanner
    },
    data() {
      return {
        // @NOTE: we keep a copy of flags locally so we can dismiss a modal as
        //  soon as the user clicks it instead of having it tied to an
        //  asynchronous action (this also allows the banner to stay dismissed
        //  if the flag update fails)
        flags: {
          hasDismissedFailedPaymentBanner: false,
          hasDismissedPendingDowngradeBanner: false,
          hasDismissedActionLimitWarningBanner: false,
        },
      }
    },
    methods: {
      dismiss(userFlagName) {

        if (typeof this.flags[userFlagName] !== 'boolean') return null

        this.flags[userFlagName] = true

        return this.$store.dispatch('user/UPDATE_USER_FLAGS', { [userFlagName]: true })
          .catch(() => {
            // do nothing, since the banner was at least dismissed locally...
          })

      },
    },
  }

</script>

<style lang="stylus" scoped>

  .banner
    @apply z-0
    @apply w-full
    @apply shadow-lg
    @apply bg-neutral-100
    @apply border-neutral-500

    &+.banner
      @apply border-t

    &.info
      @apply bg-neutral-100
      @apply border-neutral-500

    &.warning
      @apply bg-warning-100
      @apply border-warning-500

    &.error
      @apply bg-danger-100
      @apply border-danger-300

    .columned
      @apply flex
      @apply gap-6

    a
    .link
      @apply text-black

      &:not(.button)
        @apply underline

      &:hover
        @apply text-gray-700

    button
    .button
      @apply whitespace-nowrap

    .alert
      @apply m-0
      @apply shadow-none

    .close-button
      svg
        @apply w-3
        @apply h-3

</style>
