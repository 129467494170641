<template>
  <div>
    <header>
      <div class="container flex gap-4">

        <div class="logo" v-party-mode-activator>
          <router-link to="/dashboard" title="Esprezzo Dispatch">
            <img src="@/assets/images/esprezzo-e.png" alt="Esprezzo Dispatch" />
          </router-link>
        </div>

        <div class="links">
          <router-link to="/dashboard">Dashboard</router-link>
          <button
            ref="patchesButton"
            @click="togglePatchesMenu"
            :class="{ 'open': isPatchesMenuOpen, 'active': isPatchesActive }"
          >
            Patches
            <ChevronIcon />
            <div class="patches-menu" :class="{ 'is-open': isPatchesMenuOpen }">
              <router-link to="/patches/overview">Overview</router-link>
              <router-link to="/patches/history">History</router-link>
            </div>
          </button>
          <router-link to="/contracts/overview">Contracts</router-link>
          <router-link to="/monitor">Monitor</router-link>
          <router-link to="/explore">Explore</router-link>
        </div>

        <div class="menu-buttons">
          <router-link
            class="create-button button small"
            :class="{ 'disabled': isAPIReadOnly }"
            :to="{ name: isAPIReadOnly ? null : 'PatchCreate', params: { referrerLink: 'navigation-header' } }"
          >
            Create
          </router-link>

          <span class="flex-grow" />

          <a class="docs-button" target="_blank" href="https://docs.esprezzo.io/">
            <QuestionIcon />
          </a>

          <div class="user-buttons" :class="{ 'with-dev-buttons': showDevButtons }">
            <OverflowMenu
              :menuMargin="8"
              class="dev-button"
              v-if="showDevButtons"
              ref="accountSwitcherButton"
            >
              <template #trigger>
                <button class="user-button secondary">
                  <AlienIcon />
                </button>
              </template>
              <div class="user-menu">
                <LoadingOverlay v-if="isUserDataLoading" />
                <template
                  :key="accountSwitcherUser.username"
                  v-for="accountSwitcherUser in accountSwitcherUsers"
                >
                  <button
                    class="flex flex-col link name-badge"
                    @click="switchUser(accountSwitcherUser)"
                    :class="{ 'active': user && accountSwitcherUser.username === user.username }"
                  >
                    <strong class="self-start">{{ accountSwitcherUser.username }}</strong>
                    <div class="self-start email">{{ accountSwitcherUser.email }}</div>
                  </button>
                  <hr />
                </template>
              </div>
            </OverflowMenu>

            <OverflowMenu :menuMargin="8">
              <template #trigger>
                <button class="user-button">
                  <AstronautIcon />
                </button>
              </template>
              <div class="user-menu">
                <div class="name-badge" v-if="user">
                  <strong>{{ user.username }}</strong>
                  <div class="email">{{ user.email }}</div>
                  <div class="email break-word" v-if="!isProduction">{{ user.id }}</div>
                </div>
                <hr />
                <template v-if="isAdmin">
                  <router-link to="/admin">Admin</router-link>
                  <router-link to="/style-guide">Style Guide</router-link>
                  <hr />
                </template>
                <router-link to="/account/settings">Settings</router-link>
                <router-link to="/account/usage-and-billing">Usage &amp; billing</router-link>
                <router-link to="/account/connected-apps">Connected apps</router-link>
                <router-link to="/account/plans">Plans</router-link>
                <hr />
                <button class="link" @click="logout">Log Out</button>
              </div>
            </OverflowMenu>
          </div>

          <button
            ref="mobileButton"
            class="mobile-button"
            @click="toggleMobileMenu"
          >
            <MenuIcon />
            <div class="mobile-menu" :class="{ 'is-open': isMobileMenuOpen }">
              <router-link to="/dashboard">Dashboard</router-link>
              <router-link to="/patches/overview" :class="{ 'active': isPatchesActive }">Patches</router-link>
              <router-link to="/contracts/overview">Contracts</router-link>
              <router-link to="/monitor">Monitor</router-link>
              <router-link to="/explore">Explore</router-link>
              <router-link to="/account/settings">Settings</router-link>
              <router-link to="/admin" v-if="isAdmin">Admin</router-link>
              <a target="_blank" href="https://docs.esprezzo.io/">Documentation</a>
              <button class="link" @click="logout">Log Out</button>
            </div>
          </button>

        </div>

      </div>
    </header>
    <PlanBanners />
  </div>
</template>

<script>

  import { mapState, mapGetters } from 'vuex'

  import MenuIcon from '@/assets/icons/menu.svg'
  import AlienIcon from '@/assets/icons/alien.svg'
  import ChevronIcon from '@/assets/icons/chevron.svg'
  import QuestionIcon from '@/assets/icons/question.svg'
  import AstronautIcon from '@/assets/icons/astronaut.svg'

  import PlanBanners from '@/components/page/PlanBanners.vue'
  import OverflowMenu from '@/components/utils/OverflowMenu.vue'
  import LoadingOverlay from '@/components/utils/LoadingOverlay.vue'

  export default {
    inject: ['$mixpanel'],
    components: {
      MenuIcon,
      AlienIcon,
      ChevronIcon,
      PlanBanners,
      QuestionIcon,
      OverflowMenu,
      AstronautIcon,
      LoadingOverlay,
    },
    computed: {
      ...mapGetters('user', ['isAdmin']),
      ...mapState('app', ['isAPIReadOnly']),
      ...mapState('user', ['user', 'isUserDataLoading']),
      $body() {
        return document.body
      },
      $mobileButton() {
        return this.$refs.mobileButton
      },
      $patchesButton() {
        return this.$refs.patchesButton
      },
      isPatchesActive() {
        const currentRouteName = this.$route.name
        return (
          currentRouteName === 'PatchOverview'
          || currentRouteName === 'PatchHistory'
        )
      },
      showDevButtons() {
        return !this.isProduction && this.accountSwitcherUsers.length > 1
      },
    },
    data() {
      return {
        isMobileMenuOpen: false,
        isPatchesMenuOpen: false,
        accountSwitcherUsers: [],
        isProduction: process.env.VUE_APP_ENV === 'production',
      }
    },
    watch: {
      isPatchesMenuOpen(newValue, oldValue) {
        if (!newValue) {
          this.removeEventListeners()
        }
      },
    },
    beforeUnmount() {
      this.removeEventListeners()
    },
    created() {
      this.accountSwitcherUsers = JSON
        .parse(window.localStorage.getItem('dispatch.accountSwitcherUsers') || '[]')
        .filter((accountSwitcherUser) => {
          return (
            accountSwitcherUser.username
            && accountSwitcherUser.email
            && accountSwitcherUser.password
          )
        })
    },
    methods: {
      toggleMobileMenu() {
        this.isMobileMenuOpen = !this.isMobileMenuOpen
        if (this.isMobileMenuOpen) this.addEventListeners(this.$mobileButton)
      },
      togglePatchesMenu(event) {
        if (this.isPatchesMenuOpen && event.target === this.$patchesButton) {
          this.$router.push('/patches/overview')
        }
        this.isPatchesMenuOpen = !this.isPatchesMenuOpen
        if (this.isPatchesMenuOpen) this.addEventListeners(this.$patchesButton)
      },
      addEventListeners(button) {
        this.$body.addEventListener('mousedown', this.bodyClickHandler)
        if (button) button.addEventListener('mousedown', this.stopPropagation)
      },
      removeEventListeners() {

        this.isMobileMenuOpen = false
        this.isPatchesMenuOpen = false

        this.$body.removeEventListener('mousedown', this.bodyClickHandler)

        if (this.$mobileButton) {
          this.$mobileButton.removeEventListener('mousedown', this.stopPropagation)
        }

        if (this.$patchesButton) {
          this.$patchesButton.removeEventListener('mousedown', this.stopPropagation)
        }
      },
      bodyClickHandler($event) {
        this.isMobileMenuOpen = false
        this.isPatchesMenuOpen = false
      },
      stopPropagation($event) {
        $event.stopPropagation()
      },
      logout() {

        this.$mixpanel.onReady((mixpanel) => {
          mixpanel.track('Explicitly Logged Out')
        })

        this.$store.dispatch('app/LOGOUT')

      },
      switchUser(user) {

        if (this.$refs.accountSwitcherButton && typeof this.$refs.accountSwitcherButton.closeMenu === 'function') {
          this.$refs.accountSwitcherButton.closeMenu()
        }

        this.$store.commit('user/SET_IS_USER_DATA_LOADING', true)

        return this.$recaptcha('login')
          .then((recaptchaToken) => {

            return this.$store.state.api.dispatch.post('/session', { ...user, recaptchaToken })
              .then((response) => {

                const newAuthTokenData = {
                  newAuthToken: response.data.accessToken,
                  newAuthTokenExpiry: response.data.expiresAt,
                }

                return this.$store.dispatch('user/UPDATE_AUTH_TOKEN', newAuthTokenData)
                  .then(() => {
                    this.$store.commit('user/SET_IS_USER_DATA_LOADING', false)
                    return this.$store.dispatch('app/AFTER_LOGIN')
                  })

              })

          })
          .catch((error) => {

            this.$store.dispatch('toast/CREATE_TOAST', {
              text: `Could not login as ${user.username}.`,
              type: 'error',
            })

            this.$store.commit('user/SET_IS_USER_DATA_LOADING', false)

          })

      },
    },
  }

</script>

<style lang="stylus" scoped>

  underline()
    @apply underline

    text-underline-offset: 4px
    text-decoration-thickness: 2px
    text-decoration-color: theme('colors.primary.500')

  header
    @apply z-10
    @apply h-16
    @apply relative
    @apply text-white
    @apply bg-purple-1000

    @apply flex
    @apply items-center

    min-height: theme('height.16')
    max-height: theme('height.16')

  .container
    @apply px-4
    @apply h-full
    @apply mx-auto

    @apply flex
    @apply items-center

  .logo
    @apply flex-shrink-0

    img
      @apply w-6
      @apply h-6

  .create-button
    @apply hidden

    +breakpoint(md)
      @apply flex

  .links
    @apply hidden
    @apply h-full

    +breakpoint(sm)
      @apply flex

    svg
      @apply w-4
      @apply h-4
      @apply mt-1
      @apply ml-1
      @apply rotate-0
      @apply transform
      @apply transition-transform

    a
    button
      @apply p-4
      @apply h-full
      @apply relative
      @apply text-base
      @apply text-white
      @apply text-center
      @apply font-normal
      @apply rounded-none
      @apply bg-transparent

      @apply flex
      @apply items-center

      &.active
      &.router-link-exact-active
        underline()
        @apply font-semibold
        @apply bg-purple-1000

        svg
          @apply text-primary-500

      &:hover
        @apply text-white
        @apply no-underline
        @apply bg-purple-900

        &.active
        &.router-link-exact-active
          underline()

        svg
          @apply text-primary-500

      &:active
      &.active
        @apply bg-purple-800

    button
      &.open
        @apply bg-purple-900

        svg
          @apply -rotate-180
          @apply text-primary-500

  .patches-menu
    @apply z-10
    @apply w-40
    @apply hidden
    @apply left-0
    @apply top-full
    @apply absolute
    @apply shadow-xl
    @apply bg-purple-900

    margin-left: -1px

    &.is-open
      @apply block

    a
      &.router-link-exact-active
        @apply bg-purple-900

      &:hover
        @apply bg-purple-800

      &:active
      &.active
        @apply bg-purple-700

  .menu-buttons
    @apply flex
    @apply gap-4
    @apply flex-grow
    @apply items-center

  .user-buttons
    @apply flex
    @apply gap-4

    &.with-dev-buttons
      @apply h-8
      @apply w-8
      @apply relative
      @apply transition-all

      .overflow-menu-container
        @apply z-10
        @apply right-0
        @apply absolute

      .dev-button
        @apply z-0
        @apply transform
        @apply transition-all
        @apply pointer-events-none

        button
          @apply text-white

      &:hover
        @apply w-20

        .dev-button
          @apply -translate-x-12
          @apply pointer-events-auto

  .docs-button
  .user-button
    @apply p-0
    @apply hidden
    @apply min-h-0
    @apply border-0
    @apply rounded-full

    +breakpoint(sm)
      @apply flex
      @apply items-center
      @apply justify-center

  .docs-button
    @apply w-6
    @apply h-6
    @apply bg-purple-200
    @apply text-purple-1000

    &:active:not([disabled])
    &:hover:not([disabled])
      @apply bg-purple-300

    svg
      @apply w-3
      @apply h-3

  .user-button
    @apply w-8
    @apply h-8
    @apply to-primary-300
    @apply from-primary-500
    @apply bg-gradient-to-r

    &.secondary
      @apply to-purple-300
      @apply from-purple-400

  .mobile-button
    @apply w-8
    @apply h-8
    @apply p-0
    @apply bg-transparent

    @apply flex
    @apply items-center
    @apply justify-center

    min-width: theme('width.8')
    min-height: theme('height.8')

    +breakpoint(sm)
      @apply hidden

    svg
      @apply w-full
      @apply h-full

    &:hover:not([disabled])
    &:active:hover:not([disabled])
    &.active:hover:not([disabled])
      @apply bg-transparent
      @apply border-transparent

    .mobile-menu
      @apply z-40
      @apply w-full
      @apply hidden
      @apply left-0
      @apply top-full
      @apply absolute
      @apply bg-white
      @apply shadow-xl
      @apply bg-purple-900

      &.is-open
        @apply block

      a
      .link
        @apply p-4
        @apply block
        @apply w-full
        @apply text-base
        @apply text-white
        @apply text-center
        @apply rounded-none
        @apply bg-purple-900

        &.active
        &.router-link-exact-active
          underline()
          @apply bg-purple-900

        &:hover
          @apply no-underline
          @apply font-semibold
          @apply bg-purple-1000

          &.active
          &.router-link-exact-active
            underline()

        &:active
        &.active
          @apply bg-purple-800

  .user-menu
    max-width: 16rem

    hr
      @apply border-gray-400

    .name-badge
      @apply px-4
      @apply py-2

      .email
        @apply text-sm
        @apply break-all
        @apply text-gray-600

</style>
