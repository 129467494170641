import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { titleCase } from 'title-case'

import Mixpanel from '@/plugins/mixpanel'

export default (props) => {

  const isLoadingStripeBillingPortal = ref(false)

  // composables
  const store = useStore()
  const router = useRouter()

  // methods
  // @NOTE: type should be either "payment-methods" or "invoices"
  const openStripeBillingPortal = (type = 'invoices') => {

    if (!store.state.user.userPlan.stripeCustomerId) return

    isLoadingStripeBillingPortal.value = true

    const formData = {
      returnUrl: `${process.env.VUE_APP_DISPATCH_URL}${router.currentRoute.value.path}`,
    }

    if (type === 'payment-methods') {
      formData.flowData = {
        type: 'payment_method_update',
      }
    }

    console.log(type)

    store.state.api.dispatch.post('/stripe/session/billing', formData)
      .then((response) => {

        Mixpanel.onReady((mixpanel) => {

          mixpanel.track(`Stripe Billing Portal Opened (${titleCase(type.replaceAll('-', ' '))})`, { type })

          // @NOTE: we don't set isLoadingStripeBillingPortal to false here (or
          //  in a .finally() below) so the links stay disbaled as the page
          //  navigates away to stripe
          window.location = response.data.url

        })

      })
      .catch(() => {
        isLoadingStripeBillingPortal.value = false
        store.dispatch('toast/CREATE_TOAST', {
          text: 'Could not load Stripe billing portal. Please try again later.',
          type: 'error',
        })
      })

  }

  return {
    openStripeBillingPortal,
    isLoadingStripeBillingPortal,
  }

}
